import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ApiService } from './services/api.service';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { LoginComponent } from './login/login.component';
import { SignupComponent } from './signup/signup.component';
import { OtpComponent } from './otp/otp.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { CampaignHomeComponent } from './campaign-home/campaign-home.component';
import { HeaderComponent } from './shared/header/header.component';
import { NgxSpinnerModule } from 'ngx-spinner';
import { ToastrModule } from 'ngx-toastr';
import { BsModalRef, ModalModule } from 'ngx-bootstrap/modal';
import { CookieService } from './services/cookie.service';
import { ConstantsService } from './services/constants.service';
import { DisableKeyPressDirective } from './common/directives/disable-key-press/disable-key-press.directive';

// AoT requires an exported function for factories
// export function HttpLoaderFactory(httpClient: HttpClient) {
//   return new TranslateHttpLoader(httpClient);
// }

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    SignupComponent,
    OtpComponent,
    DashboardComponent,
    CampaignHomeComponent,
    HeaderComponent,
    DisableKeyPressDirective
   ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    ReactiveFormsModule,
    HttpClientModule,
    NgxSpinnerModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot({ timeOut: 3000 }),
    ModalModule.forRoot(),
    FormsModule,
  ],
  providers: [ApiService, CookieService, ConstantsService, BsModalRef],
  bootstrap: [AppComponent],
})
export class AppModule {}
