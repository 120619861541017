import { Injectable } from '@angular/core';
import { environment as env } from '../../environments/environment';
import { HttpClient, HttpHeaders, HttpInterceptor } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs/internal/Observable';
import { catchError } from 'rxjs/internal/operators/catchError';
import Swal from 'sweetalert2';
import { CookieService } from './cookie.service';
import { Router } from '@angular/router';
import { of } from 'rxjs/internal/observable/of';


@Injectable({
  providedIn: 'root',
})
export class ApiService {
  constructor(private http: HttpClient, private toaster: ToastrService,public router:Router,public cookie: CookieService,) {}

  showToaster(msg: any) {
    this.toaster.info(msg);
  }
  interCeptor = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    }),
    withCredentials: true,
  };


  confirmBox(){
    Swal.fire({
      title: 'Sorry, Your token expired!',
      text: 'We\'ll need to re-login to refresh the token.',
      icon: 'warning',
      showCancelButton: false,
      confirmButtonText: 'Ok',
    }).then((result:any) => {
      this.router.navigateByUrl('/login');
      this.cookie.deleteCookie('userToken');
      localStorage.clear();    })
  }

 

  get(url: any, params: any = ""): Observable<any> {
    const token:any = localStorage.getItem('token');
    this.interCeptor = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'x-user-token': token ? token : ""
      }),
      withCredentials: true,
    };

    return this.http.get<any>(env.AUTH_BASE_URL + url, this.interCeptor).pipe(
      catchError(error => {
          let errorMsg: string = "";
          console.log("error",error)
          if(error.status === 401){
            errorMsg = "Invalid Token"
            this.confirmBox();
          }
          return of(error);
      })
  );
  }

  post(url: any, body: any): Observable<any> {
    const token:any = localStorage.getItem('token');
    this.interCeptor = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'x-user-token': token ? token : "",
      }),
      withCredentials: true,
    };
    return this.http.post<any>(env.AUTH_BASE_URL + url, body,this.interCeptor).pipe(
      catchError(error => {
          let errorMsg: string = "";
          console.log("error",error)
          if(error.status === 401){
            errorMsg = "Invalid Token"
            this.confirmBox();
          }
          return of(error);
      })
  );
  }

  put(url:any,body:any) : Observable<any> {
    const token:any = localStorage.getItem('token');

    this.interCeptor = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'x-user-token': token,
      }),
      withCredentials: true,
    };
    return this.http.put(
      env.AUTH_BASE_URL + url,
      body,
      this.interCeptor
    ).pipe(
      catchError(error => {
          let errorMsg: string = "";
          if(error.status === 401){
            errorMsg = "Invalid Token"
            this.confirmBox();
          }
          return of(error);
      })
  );
  }

  delete(url:any) : Observable<any> {
    const token:any = localStorage.getItem('token');

    this.interCeptor = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'x-user-token':  token,
      }),
      withCredentials: true,
    };
    return this.http.delete(
      env.AUTH_BASE_URL + url,
      this.interCeptor
    ).pipe(
      catchError(error => {
          let errorMsg: string = "";
          if(error.status === 401){
            errorMsg = "Invalid Token"
            this.confirmBox();
          }
          return of(error);
      })
  );
  }

  otpGenerate(mobileNumber: any) {
    return this.get(`/otp/generate?mobile=${mobileNumber}`)
  }

  otpValidate(formData: any) {
    return this.post('/otp/validate',formData)
  }

  getCampaign(token) {
    return this.get('/campaign?active=true')
  }

  getPriorities(token) {
   
    return this.get('/priority')
  }

  getCustomer(token, mobile, campaignid, priority, type) {
   
    return this.get(`/customer?mobile=${mobile}&campaign_id=${campaignid}&priority=${priority}&type=${type}`)
  }

  getPrioritiesByCampaignId(token, campaignid) {
  
    return this.get(`/campaign/${campaignid}`)
  }

  getCampaignById(token, campaignid) {
   
    return this.get(`/campaign/single-campaign/${campaignid}`)
  }
  getCustomerFilter(token, formdata) {
   
    return this.post(`/customer/filter-customer`,formdata)
  }

  updateCustomer(token, id, formdata) {
   
    return this.put(`/customer/${id}`,formdata)
  }

  logout(token) {
    return this.put(`/otp/logout`,null)
  }
}
