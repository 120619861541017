import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from './api.service';
import { CookieService } from './cookie.service';

@Injectable({
  providedIn: 'root'
})
export class ConstantsService {
  tokenExpiryTime: any;
  token: string;

  constructor(
    private router: Router,
    private apiService: ApiService,
    public cookie: CookieService
  ) { }
  ngOnInit(): void {
  }

  getTokenExpiryTime(){
    var tokenExpiryTime = localStorage.getItem('tokenExpiryTime');
  
    let currentDate: Date = new Date();
    const start = new Date(currentDate).getTime();
    const end = new Date(tokenExpiryTime).getTime();

    var diffTime = (end - start)/1000;
    diffTime = diffTime/60;
    let time = Math.round(diffTime)
      
    return time;
   
  }

  isTokenAvailable(){
    this.token = localStorage.getItem('token');
    this.tokenExpiryTime = this.getTokenExpiryTime()
             
    if (!localStorage.getItem('token') || this.tokenExpiryTime > 1440) {
      if(this.token){
        this.apiService.logout(this.token).subscribe((res) => {
        });
      }
      this.router.navigateByUrl('/login');
      localStorage.clear();
      this.cookie.deleteCookie('userToken');
    }
  }
}
