import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { NgxSpinnerService } from 'ngx-spinner';
import { ApiService } from '../services/api.service';
import { ConstantsService } from '../services/constants.service';
import { CookieService } from '../services/cookie.service';
import { environment as env } from '../../environments/environment';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent implements OnInit {
  token: string;
  campaigns: any[] = [];
  tokenExpiryTime: any;
  welcomeMessage: string;
  env: any;
  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    private apiService: ApiService,
    private spinner: NgxSpinnerService,
    public cookie: CookieService,
    public constantsService: ConstantsService
  ) {}

  ngOnInit(): void {
    this.env = env;
    this.token = localStorage.getItem('token');
    this.constantsService.isTokenAvailable();
    this.getCapmpgain();
  }

  getCapmpgain() {
    this.spinner.show();
    this.apiService.getCampaign(this.token).subscribe(
      (res) => {
        this.spinner.hide();
        console.log(res);
        if (res['status'] == 'success') {
          this.campaigns = res['result'].capData;
          this.welcomeMessage = `Welcome ${res['result'].validEmployee.first_name} ${res['result'].validEmployee.last_name} (Store: ${res['result'].validEmployee.store_code})`
          this.campaigns.forEach((campaign) => {
            let date = campaign.updated_at;
            campaign.updated_at = moment(campaign.updated_at).format(
              'Do MMMM YYYY'
            );
          });
          console.log(this.campaigns);
        }
      },
      (err) => {
        this.spinner.hide();
        console.log(err);
      }
    );
  }

  logout() {
    this.apiService.logout(this.token).subscribe((res) => {
      console.log(res);
      this.router.navigateByUrl('/login');
    });
    this.cookie.deleteCookie('userToken');
    localStorage.clear();
  }

  handleGoBtn(id) {
    let data = {
      data: id,
    };
    this.router.navigate(['/campaign-home', data]);
  }
}
