import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from '../services/api.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { CookieService } from '../services/cookie.service';
import * as moment from 'moment';
import { environment as env } from '../../environments/environment';

@Component({
  selector: 'app-otp',
  templateUrl: './otp.component.html',
  styleUrls: ['./otp.component.scss'],
})
export class OtpComponent implements OnInit {
  otpForm: FormGroup;
  submittedOtpForm: boolean = false;
  errorMsg: string;
  env: any;
  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    private apiService: ApiService,
    private route: ActivatedRoute,
    private spinner: NgxSpinnerService,
    public cookie: CookieService
  ) {}

  ngOnInit(): void {
 
    this.env = env;
    localStorage.setItem('mobile', this.route.snapshot?.params?.mobileNumber);
    console.log(this.route.snapshot.params.mobileNumber);
    this.otpForm = this.formBuilder.group({
      otp: ['', [Validators.required]],
    });
  }
  get otpFormVal() {
    return this.otpForm.controls;
  }

  onlyNumbers(ev) {
    this.errorMsg = '';
    let onlynum = /^[0-9]*$/;
    if (!onlynum.test(ev.key)) {
      ev.preventDefault();
    }
  }

  onSubmitOtpForm() {
     this.submittedOtpForm = true;
    this.spinner.show();
    console.log(this.otpFormVal);
    if (this.otpForm.invalid) {
      this.spinner.hide();
      return;
    } else {
      let formdata = {
        mobile: this.route.snapshot?.params?.mobileNumber,
        value: this.otpForm.controls['otp'].value,
      };
      this.apiService.otpValidate(formdata).subscribe(
        (res) => {
          this.spinner.hide();
          console.log(res['status']);
          if (res['status'] === 'success') {
            this.errorMsg = '';
            console.log(res['result']);
            this.router.navigate(['/dashboard']);
            let expireDays = 1;
            let d: Date = new Date();
            d.setTime(
              d.getTime() +
                (expireDays ? expireDays : 1) * 24 * 60 * 60 * 1000
            );
            d.toUTCString();
            localStorage.setItem('token', res['result'].token);
            localStorage.setItem('tokenExpiryTime', d.toString());
          }

          if (localStorage.token) {
            this.setCookie(localStorage.token);
          }
        },
        (err) => {
          this.spinner.hide();
          console.log(err['status']);
          if (err['status'] == 400) {
            this.spinner.hide();
            this.errorMsg = 'Invalid OTP';
            // alert(this.errorMsg);
          } else if (err['status'] == 504) {
            this.spinner.hide();
            this.errorMsg = 'Took too long to respond';
            // alert(this.errorMsg);
          }
        }
      );
    }
  }

  setCookie(token: any) {
    this.cookie.setCookie({
      name: 'userToken',
      value: localStorage.token,
      //session: true,
      expireDays: 0.0008,
    });
  }
}
