import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[disableKeyPress]'
})
export class DisableKeyPressDirective {

  constructor(private el: ElementRef) { }

  @HostListener('document:keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    // For Windows
    if ((event.ctrlKey && event.keyCode === 65) || (event.ctrlKey && event.keyCode == 67)) {
      event.preventDefault();
    }
    // For Mac
    if (((event.metaKey || event.ctrlKey) && event.keyCode === 65) || (event.metaKey || event.ctrlKey) && event.keyCode == 67) {
      event.preventDefault();
    }
  }

}
