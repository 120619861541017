import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { ApiService } from '../services/api.service';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { CookieService } from '../services/cookie.service';
import { ConstantsService } from '../services/constants.service';
import * as moment from 'moment';
import { environment as env } from '../../environments/environment';


@Component({
  selector: 'app-campaign-home',
  templateUrl: './campaign-home.component.html',
  styleUrls: ['./campaign-home.component.scss'],
})
export class CampaignHomeComponent implements OnInit {
  campaignForm: FormGroup;
  priorityForm: FormGroup;
  priorityArr: any[] = [];
  typeArr: any[] = [];
  uniqueTypeArray: any[];
  uniqueTypeString: any = '';
  uniquePriorityArray: any[];
  uniquePriorityString: any = '';
  token: string;
  priorityList: any;
  mobile: string;
  customerData: any;
  incorrectCount: any;
  toatalCount: any;
  completedCount: any;
  dncCount: any;
  callBackuCount: any;
  campaignid: any;
  isAttending: any;
  customerID: any;
  formdata: any;
  filteredPriorityList: any[] = [];
  radioErrorMsg: any = {};
  btmDisable: any = {};
  isViewDetailModal: boolean = false;
  isPrioritySelected: boolean = false;
  priorities: any = [];
  allCustomFieldToShow: any = [];
  allFieldToShow: any = [];
  priorityFilterData: any = [];
  customeField: string = '';
  btnDisable: boolean = false;
  buyTicketForm: FormGroup;
  isMobile: boolean;
  env: any;

  @ViewChild('errorPopup', { static: true }) errorPopup: ModalDirective;
  myForm: any;
  myRadioForm: any;
  cardShow: boolean = false;
  showCallLaterDisable: boolean = false;
  constructor(
    private formBuilder: FormBuilder,
    private apiService: ApiService,
    private spinner: NgxSpinnerService,
    private router: Router,
    private route: ActivatedRoute,
    private toaster: ToastrService,
    public cookie: CookieService,
    public constantsService: ConstantsService,
    protected cd: ChangeDetectorRef
  ) {
    const mediaQuery = window.matchMedia('(max-width: 768px)');
    mediaQuery.matches ? (this.cardShow = true) : (this.cardShow = false);

    this.campaignid = this.route.snapshot.params.data;
  }

  ngOnInit(): void {
    this.checkIfMobile();
    this.constantsService.isTokenAvailable();
    this.token = localStorage.getItem('token');
    this.mobile = localStorage.getItem('mobile');
    this.campaignForm = this.formBuilder.group({
      isIntrestedInEOSS: [''],
      isNotIntrestedInEOSS: [''],
    });
    this.getCustomer();
    this.getPriorities();
    this.myForm = this.formBuilder.array([]);
    this.myRadioForm = this.formBuilder.array([]);
    this.env = env;
  }

  checkIfMobile() {
    this.isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
  }

  get tickets(): FormArray {
    return <FormArray>this.buyTicketForm.get('tickets');
  }
  createItem(key: string, value: string,type:string) {
    return this.formBuilder.group({
      key: key,
      value: value,
      updateValue: '',
      radio: [''],
      type:type
    });
  }
  createRadio(key: string, value: string) {
    return this.formBuilder.group({
      attending: ['', Validators.required],
    });
  }
  getPriorities() {
    this.spinner.show();
    this.apiService
      .getPrioritiesByCampaignId(this.token, this.campaignid)
      .subscribe(
        (res: any) => {
          this.spinner.hide();
          res.result.forEach((item: any) => {
            this.priorities.push(item);
          });
        },
        (err) => {
          this.spinner.hide();
        }
      );
  }
  getCustomer() {
    this.spinner.show();
    this.apiService.getCampaignById(this.token, this.campaignid).subscribe(
      (res: any) => {
        if (res.status == 'success') {
          if (res.result.capData.tbl_campaign_customfields) {
            this.allCustomFieldToShow =
              res.result.capData.tbl_campaign_customfields.filter(
                (e: any) => e.visibility === true
              );
          }
          this.apiService
            .getCustomer(
              this.token,
              this.mobile,
              this.campaignid,
              this.uniquePriorityString,
              this.uniqueTypeString
            )
            .subscribe(
              (res) => {
                this.spinner.hide();
                this.customerData = res['result']?.customer_data;
                this.customerData.forEach((item: any) => {
                  item['disable'] = false;
                  item['disableCompleted'] = true;
                  this.myRadioForm.push(
                    this.formBuilder.array(
                      [1].map((key) => this.createRadio('key', 'key'))
                    )
                  );
                });
                let finalAllCustome: any = [];
                let allCustomFields = this.allCustomFieldToShow;
                this.allCustomFieldToShow = this.allCustomFieldToShow.map(
                  (e) => e.field_name
                );
                this.customerData.map((e: any) => {
                  if (e.original_customfield) {
                    let allCustomOfCustomer = Object.keys(
                      e.original_customfield
                    );
                    let fieldToCompare = this.allCustomFieldToShow;
                    // let finalResult = allCustomOfCustomer.filter(function (v) {
                    //   return fieldToCompare.indexOf(v) > -1;
                    // });
                    let cutomeField = e.original_customfield;
                    for (const eachKey of fieldToCompare) {
                      finalAllCustome.push({
                        customerId: e.first_name,
                        key: eachKey,
                        value: cutomeField[eachKey] ? cutomeField[eachKey]  : "",
                        type: allCustomFields.find(values => values.field_name === eachKey)?.field_type ?  allCustomFields.find(values => values.field_name === eachKey)?.field_type : "string"
                      });
                    }
                    e.finalCustom = finalAllCustome;
                    finalAllCustome = [];
                    this.myForm.push(
                      this.formBuilder.array(
                        fieldToCompare.map((key) =>
                          this.createItem(key, cutomeField[key] ? cutomeField[key] : "" , allCustomFields.find(values => values.field_name === key)?.field_type)
                        )
                      )
                    );
                  }else{
                   
                    let fieldToCompare = this.allCustomFieldToShow;
                    
                    
                    for (const eachKey of fieldToCompare) {
                      const customType = allCustomFields.find(values => values.field_name === eachKey)?.field_type ?  allCustomFields.find(values => values.field_name === eachKey)?.field_type : "string"
                      finalAllCustome.push({
                        customerId: e.first_name,
                        key: eachKey,
                        value: "",
                        type: customType

                      });
                    }
                    e.finalCustom = finalAllCustome;
                    finalAllCustome = [];
                    this.myForm.push(
                      this.formBuilder.array(
                        fieldToCompare.map((key) =>
                          this.createItem(key, "",allCustomFields.find(values => values.field_name === key)?.field_type ? allCustomFields.find(values => values.field_name === key)?.field_type : 'string' )
                        )
                      )
                    );

                  }
                });
              },
              (err) => {
                this.spinner.hide();
              }
            );
        } else {
          this.toaster.error(`Campaign Not found..`);
        }
      },
      (err) => {}
    );
  }
  onSubmitCampaignForm() {}

  viewDetails() {
    this.isViewDetailModal = true;
    let reqObj: any = [];
    reqObj = {
      priority: this.uniquePriorityArray,
      campaign_id : this.campaignid
    };
    this.apiService.getCustomerFilter(this.token, reqObj).subscribe((res) => {
      this.priorityFilterData = res;
      this.priorityFilterData.result &&
        this.priorityFilterData.result.forEach((item: any) => {
          var pendingCount =
            item.total -
            parseInt(item.CallLater) -
            parseInt(item.doNotCall) -
            parseInt(item.completedCount) -
            parseInt(item.incorrectNumber); // - item.isAttending - item.notAttending
          item['pendingByTotal'] = pendingCount + '/' + item.total;
        });
    });
  }

  onCancelClick() {
    this.isViewDetailModal = false;
  }

  changeSelection(event) {
    if (event.target.checked == true) {
      this.priorityArr.push(event.target.value);
    } else {
      this.priorityArr = this.priorityArr.filter((item) => {
        return item != event.target.value;
      });
    }
    this.uniquePriorityArray = [...new Set(this.priorityArr)];
    this.uniquePriorityString = '';
    this.uniquePriorityString = this.uniquePriorityArray.join(',');

    if (event.target.value == 'all') {
      if (event.target.checked == true) {
        var inp = document.querySelectorAll('.checkall');
        this.uniquePriorityString = 'ALL';
        for (var i = 0; i < inp.length; i++) {
          inp[i]['checked'] = true;
        }
      } else {
        var inp = document.querySelectorAll('.checkall');
        this.priorityArr = [];
        this.uniquePriorityArray = [];
        this.uniquePriorityString = '';
        for (var i = 0; i < inp.length; i++) {
          inp[i]['checked'] = false;
        }
      }
    }

    this.isPrioritySelected =
      this.uniquePriorityArray.length > 0 ? true : false;
    this.getCustomer();
  }
  changeTypeSelection(event) {
    this.uniqueTypeString = event.target.value;
    this.myRadioForm.clear();
    this.myForm.clear();
    if (this.uniqueTypeString === 'call_later') {
      this.showCallLaterDisable = true;
      // this.myForm.controls.disable()
      // this.myRadioForm.controls.disable();
    }

    // if (event.target.checked == true) {
    //   this.typeArr.push(event.target.value);
    // } else {
    //   this.typeArr = this.typeArr.filter((item) => {
    //     return item != event.target.value;
    //   });
    // }
    // this.uniqueTypeArray = [...new Set(this.typeArr)];
    // this.uniqueTypeString = '';
    // this.uniqueTypeString = this.uniqueTypeArray.join(',');
    // if (event.target.value == 'TypeAll') {
    //   if (event.target.checked == true) {
    //     var inp = document.querySelectorAll('.checkTypeAll');
    //     this.uniqueTypeString = 'ALL';
    //     for (var i = 0; i < inp.length; i++) {
    //       inp[i]['checked'] = true;
    //     }
    //   } else {
    //     var inp = document.querySelectorAll('.checkall');
    //     this.typeArr = [];
    //     this.uniqueTypeArray = [];
    //     this.uniqueTypeString = '';
    //     for (var i = 0; i < inp.length; i++) {
    //       inp[i]['checked'] = false;
    //     }
    //   }
    // }

    // console.log(this.uniqueTypeArray);
    // console.log(this.uniqueTypeString);
    this.getCustomer();
  }
  onClickerrorModalOK() {
    localStorage.clear();
    this.router.navigateByUrl('/login');
    this.errorPopup.hide();
  }
  onlyNumbers(ev) {
    let onlynum = /^[0-9]*$/;
    if (!onlynum.test(ev.key)) {
      ev.preventDefault();
    }
  }
  onlyAlphaNumeric(ev) {
    let onlynum = /^[0-9a-zA-Z ]*$/;
    if (!onlynum.test(ev.key)) {
      ev.preventDefault();
    }
  }
  logout() {
    this.apiService.logout(this.token).subscribe((res) => {
      this.router.navigateByUrl('/login');
    });
    this.cookie.deleteCookie('userToken');
    localStorage.clear();
  }

  updateCustomer(
    data,
    customer,
    index,
    radio1?: HTMLInputElement,
    radio2?: HTMLInputElement
  ) {
    let edited_customfield = {};
    let is_attending = false;
    if (this.myForm.at(index)?.controls) {
      let allControls = this.myForm.at(index)?.controls;
      let updatedCustom = [];
      let editedCustomfield: any = {};
      for (const allControl of allControls) {
        let valueOfControl = allControl?.value;
        editedCustomfield = {
          [`${valueOfControl.key}`]: `${valueOfControl.updateValue}`,
        };
        updatedCustom.push(editedCustomfield);
      }

      Object.assign(edited_customfield, ...updatedCustom);
      this.formdata = {
        edited_customfield,
      };
    }

    if (data == 'completed_flag') {
      if (this.myRadioForm.at(index)?.controls) {
        if (this.myRadioForm.at(index)?.controls[0].status === 'INVALID') {
          this.radioErrorMsg = { [`${index}`]: 'Please select yes or no.' };
          return;
        }
        this.radioErrorMsg = {};
        if (
          this.myRadioForm.at(index)?.controls[0].value.attending === 'true'
        ) {
          is_attending = true;
        }
      }
    }
    customer.disable = true;
    this.customerID = customer.id;
    if (data == 'completed_flag') {
      customer.disableCompleted = false;
    }
    this.spinner.show();

    if (data == 'completed_flag') {
      this.formdata = {
        completed_flag: true,
        call_later_flag: false,
        incorrect_flag:false,
        do_not_call_flag:false,
        is_attending,
        edited_customfield,
        campaign_id:this.campaignid
      };
    } else if (data == 'call_later_flag') {
      this.formdata = {
        call_later_flag: true,
        completed_flag: false,
        incorrect_flag:false,
        do_not_call_flag:false,
        is_attending,
        edited_customfield,
        campaign_id:this.campaignid
      };
    } else if (data == 'incorrect_flag') {
      this.formdata = {
        incorrect_flag: true,
        call_later_flag: false,
        completed_flag: false,
        do_not_call_flag:false,
        is_attending,
        edited_customfield,
        campaign_id:this.campaignid
      };
    } else if (data == 'do_not_call_flag') {
      this.formdata = {
        do_not_call_flag: true,
        incorrect_flag: false,
        call_later_flag: false,
        completed_flag: false,
        is_attending,
        edited_customfield,
        campaign_id:this.campaignid
      };
    }
    let loadNewData = this.customerData.filter((data) => data.disable === true);
    this.apiService
      .updateCustomer(this.token, this.customerID, this.formdata)
      .subscribe(
        (res) => {
          this.radioErrorMsg = {};
          this.spinner.hide();
          customer.disableCompleted = true;
          this.myRadioForm.at(index)?.controls[0]?.disable();
          let allCustomControls = this.myForm.at(index)?.controls;
          if (allCustomControls) {
            for (let ind = 0; ind < allCustomControls.length; ind++) {
              this.myForm.at(index).controls[ind].disable();
            }
          }
          if (res['status'] == 'success') {
            this.toaster.success('Customer Updated Sucessfully');
            if (this.cardShow) {
              if (loadNewData.length === 1) {
                this.myRadioForm = this.formBuilder.array([]);
                this.myForm = this.formBuilder.array([]);
                this.getCustomer();
              }
            } else {
              if (loadNewData.length === 4) {
                this.myRadioForm = this.formBuilder.array([]);
                this.myForm = this.formBuilder.array([]);
                this.getCustomer();
              }
            }
          } else {
            this.toaster.error(
              'Customer details not updated! Please try again.'
            );
          }
        },
        (err) => {
          this.radioErrorMsg = {};
          this.spinner.hide();
          if (err?.error?.message == 'Invalid Token!') {
            this.errorPopup.show();
          } else {
            alert(err);
          }
        }
      );
  }
}
