<div class="container-fluid px-30 mb-5 block-selection">
  <div class="text-center pt-4 mb-2" routerLink="/dashboard">
    <img
      src="{{env.BASE_URL_IMAGE}}/62b8b17263017ed475b3bfe42ec6a2b2-1655270701620.png"
      class="text-center"
    />
  </div>
  <div class="back-btn pl-0 mb-3">
    <span [routerLink]="['/dashboard']">
      <!-- <img src="./assets/images/leftArrow.svg" /> -->

      <img
        src="{{env.BASE_URL_IMAGE}}/62b8b17263017ed475b3bfe42ec6a2b2-1655354910895.png"
      />
    </span>
  </div>
  <div>
    <div class="row mb-3">
      <div class="col-6 col-md-6 type-container">
        <div class="dropdown">
          <button
            class="btn dropdown-toggle"
            type="button"
            id="dropdownMenuButton"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            <div class="priority">Priority</div>
          </button>
          <div class="dropdown-menu px-3" aria-labelledby="dropdownMenuButton">
            <div *ngFor="let priority of priorities" class="">
              <label for="priorityall">{{ priority.priority_name }}</label>
              <input
                type="checkbox"
                id="priorityall"
                style="float: right; height: 20px"
                [value]="priority.priority_name"
                (change)="changeSelection($event)"
              />
            </div>
          </div>
        </div>
        <div class="mt-2 mb-2">
          <span class="font-green breakall"
            >{{ uniquePriorityString }}
            <button
              id="btn-1"
              *ngIf="isPrioritySelected"
              (click)="viewDetails()"
              type="button"
              class="view-btn"
              data-toggle="modal"
              data-target="#viewDetailsModal"
            >
              View Details
            </button>
          </span>
        </div>
      </div>
      <div class="col-6 col-md-6 type-container">
        <div class="dropdown">
          <button
            class="btn dropdown-toggle"
            type="button"
            id="dropdownMenuButton"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            <div class="typeString">Type</div>
          </button>
          <div class="dropdown-menu px-3" aria-labelledby="dropdownMenuButton">
            <div>
              <label for="completed">Not Called</label>
              <input
                type="radio"
                name="type"
                id="completed"
                style="float: right; height: 20px"
                value="not_called_flag"
                (change)="changeTypeSelection($event)"
                class="checkTypeAll"
              />
            </div>
            <div>
              <label for="call_later">Call Back</label>
              <input
                type="radio"
                name="type"
                id="call_later"
                style="float: right; height: 20px"
                value="call_later"
                (change)="changeTypeSelection($event)"
                class="checkTypeAll"
              />
            </div>
          </div>
        </div>
        <div class="mt-2 mb-2">
          <p class="font-green breakall">{{ uniqueTypeString }}</p>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="customerData != undefined" class="customer-data">
    <div class="row">
      <ng-container
        *ngFor="let customer of customerData; let indexOfelement = index"
      >
        <ng-container
          *ngIf="cardShow ? indexOfelement < 1 : indexOfelement < 4"
        >
          <div class="col-sm-3">
            <div class="container light-blue-bg pb-3 text-break" disabled>
              <h5 class="pt-4 pb-2">Customer Detail</h5>
              <div class="row fullNameSize">
                <div class="col-md-6 col-6">
                  <strong class="fs-14"> Full Name </strong>
                </div>
                <div class="col-md-6 col-6">
                  <p>{{ customer?.first_name }} {{ customer?.last_name }}</p>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6 col-6">
                  <strong class="fs-14"> Phone Number </strong>
                </div>
                <div class="col-md-6 col-6">
                  <p *ngIf="isMobile">
                    <a  href="tel:{{ customer?.mobile }}">{{customer?.mobile}}</a>
                  </p>
                  <p *ngIf="!isMobile">{{customer?.mobile}}</p>
                </div>
              </div>

              <div class="row">
                <div class="col-md-6 col-6">
                  <strong class="fs-14"> Gender</strong>
                </div>
                <div class="col-md-6 col-6">
                  <p>{{ customer?.gender }}</p>
                </div>
              </div>

              <div class="row">
                <div class="col-md-6 col-6">
                  <strong class="fs-14">Tier</strong>
                </div>
                <div class="col-md-6 col-6">
                  <p>{{ customer?.tier }}</p>
                </div>
              </div>

              <div class="row">
                <div class="col-md-6 col-6">
                  <strong class="fs-14"> Date of Birth</strong>
                </div>
                <div class="col-md-6 col-6">
                  <p>{{ customer?.dob }}</p>
                </div>
              </div>

              <div class="row">
                <div class="col-md-6 col-6">
                  <strong class="fs-14">Priority</strong>
                </div>
                <div class="col-md-6 col-6">
                  <p>{{ customer?.priority }}</p>
                </div>
              </div>

              <div *ngIf="myForm.at(indexOfelement) as formArr">
                <ng-container *ngFor="let ctrl of formArr.controls">
                  <!-- <div class="row">
                    <div class="col-md-6 col-6">
                      <strong class="fs-14">
                        {{ ctrl.get("key").value }}</strong
                      >
                    </div>
                    <div class="col-md-6 col-6">
                      <p>{{ ctrl.get("value").value }}</p>
                    </div>
                  </div> -->
                  <div class="row">
                    <div class="col-md-6 col-6">
                      <strong class="fs-14">{{ ctrl.get("key").value }}</strong>

                    </div>
                    <div class="col-md-6 col-6">
                      <div
                        *ngIf="ctrl.get('type').value.toLowerCase() === 'date'"
                      >
                        <input
                          type="date"
                          id="watermark"
                          class="form-control custom-input"
                          [formControl]="ctrl.get('updateValue')"
                          [(ngModel)]="ctrl.get('value').value"
                        />
                      </div>
                      <!-- <div
                        *ngIf="
                          ctrl.get('key').value.toLowerCase() === 'pincode'
                        "
                      >
                        <input
                          type="text"
                          class="form-control custom-input"
                          [formControl]="ctrl.get('updateValue')"
                          maxlength="6"
                          (keypress)="onlyNumbers($event)"
                          [(ngModel)]="ctrl.get('value').value"
                        />
                      </div> -->
                      <div
                        *ngIf="
                        ctrl.get('type').value.toLowerCase() === 'string'
                        "
                      >
                        <input
                          type="text"
                          class="form-control custom-input"
                          [formControl]="ctrl.get('updateValue')"
                          (keypress)="onlyAlphaNumeric($event)"
                          [(ngModel)]="ctrl.get('value').value"
                        />
                      </div>
                    </div>
                  </div>
                  <br />
                </ng-container>
              </div>
             
            
              <div *ngIf="myRadioForm.at(indexOfelement) as formArr2">
                <ng-container *ngFor="let ctrl of formArr2.controls">
                  <div class="row">
                    <div class="col-6 col-md-6">
                      <strong class="fs-14">Attending ?</strong>
                    </div>
                    <div class="col-6 col-md-6">
                      <div class="row">
                        <div class="col-6 col-md-6 pr-0">
                          <input
                            type="radio"
                            value="true"
                            [name]="indexOfelement"
                            #rad1
                            [formControl]="ctrl.get('attending')"
                          />
                          <label class="pl-6 ml-2"> Yes </label>
                        </div>
                        <div class="col-6 col-md-6 pr-0">
                          <input
                            type="radio"
                            value="false"
                            [name]="indexOfelement"
                            [formControl]="ctrl.get('attending')"
                            #rad2
                          />
                          <label class="pl-6 ml-2">No</label>
                        </div>
                      </div>
                      <div
                        *ngIf="radioErrorMsg[indexOfelement]"
                        class="error fs-14"
                      >
                        <p [hidden]="rad2.checked || rad1.checked">
                          *{{ radioErrorMsg[indexOfelement] }}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="buttons fs-15">
                    <div class="row d-flex justify-content-between mb-3">
                      <div class="col-6 col-md-6">
                        <button
                          type="button"
                          [disabled]="(customer.disable && customer.disableCompleted)"
                          (click)="
                            updateCustomer(
                              'completed_flag',
                              customer,
                              indexOfelement,
                              rad1,
                              rad2
                            )
                          "
                          class="p-3"
                        >
                          Completed
                        </button>
                      </div>
                      <div class="col-6 col-md-6">
                        <button
                          type="button"
                          [disabled]="customer.disable"
                          [ngStyle]="{'background-color':uniqueTypeString === 'call_later' ? 'aqua' : '' }"
                          (click)="
                            updateCustomer(
                              'call_later_flag',
                              customer,
                              indexOfelement,
                              rad1,
                              rad2
                            )
                          "
                        >
                          Call Back
                        </button>
                      </div>
                    </div>
                    <div
                      class="row d-flex justify-content-between bottom-buttons"
                    >
                      <div class="col-6 col-md-6">
                        <button
                          type="button"
                          class="p-1"
                          [disabled]="customer.disable"
                          (click)="
                            updateCustomer(
                              'incorrect_flag',
                              customer,
                              indexOfelement,
                              rad1,
                              rad2
                            )
                          "
                        >
                          Incorrect Number
                        </button>
                      </div>
                      <div class="col-6 col-md-6">
                        <button
                          type="button"
                          class="p-1"
                          [disabled]="customer.disable"
                          (click)="
                            updateCustomer(
                              'do_not_call_flag',
                              customer,
                              indexOfelement,
                              rad1,
                              rad2
                            )
                          "
                        >
                          Do not Call
                        </button>
                      </div>
                    </div>
                  </div>
                </ng-container>
              </div>
            </div>
          </div>
        </ng-container>
      </ng-container>
    </div>
    <div *ngIf="!customerData">
      <h4 class="pt-4 pb-2">No details are available</h4>
    </div>
  </div>
  <div class="buttons">
    <button (click)="logout()" type="button" class="p-1">Logout</button>
  </div>
  <div
    bsModal
    #errorPopup="bs-modal"
    class="modal fade"
    tabindex="-1"
    role="dialog"
    aria-labelledby="dialog-child-name"
  >
    <div class="modal-dialog modal-sm">
      <div class="modal-content">
        <div class="modal-body">
          <p class="text-center">
            This account is being used in another device.
          </p>
          <p class="text-center">Please login again.</p>

          <div class="text-center buttons p-0">
            <button
              type="submit"
              class="blueBtn"
              (click)="onClickerrorModalOK()"
            >
              ok
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="modal fade" id="viewDetailsModal" aria-hidden="true">
    <div class="modal-dialog view-modal modal-sm">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">View Details</h5>
          <button
            id="btn-2"
            type="button"
            class="close"
            data-dismiss="modal"
            (click)="onCancelClick()"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="row pb-3">
            <div class="col-6">
              <b>Priority Name</b>
            </div>
            <div class="col-xs-6">
              <b>Pending/Total</b>
            </div>
          </div>
          <div class="row pb-2" *ngFor="let item of priorityFilterData.result">
            <div class="col-5 ml-2">
              {{ item.priority }}
            </div>
            <div class="col-5 ml-4">
              {{ item.pendingByTotal }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
